<template>
    <div>
        <v-row style="height: 54vh; overflow-y: scroll; overflow-x: hidden; margin-bottom: 10px;">
        <v-col cols="6"  class="" v-for="form in info" :key="form.name">
            <FormInput  :input_info="form" ></FormInput>
           
        </v-col>
        
        
    </v-row>
    <v-row>
        <v-col cols="12">
            <v-btn dense block color="primary" @click="submitForm" class="text-lowercase">submit</v-btn>

            <p
                :class="submitted ? 'primary--text' : 'red--text'"
                class="caption text-center"
            >{{ message }}</p>
        </v-col>
    </v-row>
    </div>
   
</template>
<script>

import { Storage } from 'aws-amplify';
import FormInput from './FormInput.vue';
export default {
    name: 'user-form-1',
    components: {
        FormInput
    },
    data: () => ({
        map: false,
        mapStyle: "mapbox://styles/planet369/ckrdwj57j2w0m18mjfzizwmm3",
        mapboxAccessToken: 'pk.eyJ1IjoicGxhbmV0MzY5IiwiYSI6ImNrcmR3ZXZ6ZDBpbHgyb29laHR2d3pjcHUifQ.riIQ-PY811vasvyycMyn3g',
        coordinates: [-111.549668, 39.014],
        file: {},
        form: {
            first_name: '',
            last_name: '',
            location: '',
            linked_in_profile: '',
            instagram_account: '',
            video_url: '',
            request_id: '0f8603d5-c5f8-4f6d-b0fe-2f80357b6a48'
        },
        info: [{
              title: 'Name',
              field_type: 'text-field',
              label: 'Name',
              input_type: 'text',
      
              items: [],
              value: '',
              input_id: 'action_link'
      
            }, 
        {
              title: 'Date of birth',
              field_type: 'text-field',
              label: 'Date',
              input_type: 'date',
      
              items: [],
              value: '',
              input_id: 'action_link'
      
            }, 
        {
              title: 'e-mail address ',
              field_type: 'text-field',
              label: 'e-mail address ',
              input_type: 'email',
      
              items: [],
              value: '',
              input_id: 'action_link'
      
            }, 
        {
              title: 'address',
              field_type: 'text-field',
              label: 'address',
              input_type: 'text',
      
              items: [],
              value: '',
              input_id: 'action_link'
      
            }, 
        {
              title: 'telephone number',
              field_type: 'text-field',
              label: 'telephone number',
              input_type: 'text',
      
              items: [],
              value: '',
              input_id: 'action_link'
      
            }, 
            
           
            
            {
              title: 'payment mode*',
              field_type: 'select-input',
              label: '',
              input_type: 'text',
              items: ['100 USD at once', '4 x 25 USD per sections', ],
              value: '',
              input_value_condition: 'no, we already did this one:',
              value_2: '',
              label_2: 'action page link',
              input_id: 'first_action'
      
            },
          
            {
              title: 'I want to add a comment or question: ',
              field_type: 'text-field',
              label: '',
              input_type: 'text',
      
              items: [],
              value: '',
              input_id: 'action_link'
      
            },
        
        ],
        message: '',
        submitted: false,
        submit: false
    }),
    methods: {
        createGenerativeNumber(){
            return Math.floor(100000000 + Math.random() * 900000000)
        },
        mapOpen() {
            this.map = true
            console.log(this.$refs.map)
            this.$refs.map.loadMap();
        },
        async put() {
            const result = await Storage.put("test.txt", "Hello");
            console.log(result)

        },
        async add() {
            this.submit = true

            const result = await Storage.put(this.createGenerativeNumber(), this.file);
            console.log(result)
            this.form.video_url = 'https://requests6b75835a6b6b43ceae12efe85948b077.s3.eu-central-1.amazonaws.com/public/Melibee/'+ result.key

        },
        async getFiles() {
            Storage.list('/public/Melibee/') // for listing ALL files without prefix, pass '' instead
                .then(result => console.log(result))
                .catch(err => console.log(err));
        },
        async submitForm() {
            await Storage.put(this.createGenerativeNumber() + "-report-melibee.json", JSON.stringify(this.info));
            
            window.location.reload()
            console.log("here")
            
        },
        reateGenerativeNumber() {
  return Math.floor(100000000 + Math.random() * 900000000)
}
    }
}
</script>