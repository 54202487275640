<template >
  <v-container>
    <v-row class="mt-2">
      <v-col md="4" cols="12" class="my-auto">
        <h3>
          title:
          <span class="font-weight-medium"> THE GOOD GOOD LAND VOLUNTEERING CALL</span>
        </h3>
        <h5>
          objective:
          <span class="font-weight-medium">initiate a new project & share permaculture knowledge </span>
        </h5>

        <h6>
          circle: terraform permacouncil
          <br />act: we live together, we feed all
        </h6>
        <br />
        <div style="position: relative; padding-bottom: 57.05229793977813%; height: 0;"><iframe
            src="https://www.youtube.com/embed/X-mTK7DsxEM" frameborder="0" webkitallowfullscreen mozallowfullscreen
            allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div> <br>
        <v-card style="background-color: rgba(255,255,255,0.63); cursor: pointer; " @click="initiator = true">
          <v-row no-gutters>
            <v-col cols="4">
              <v-img height="81"
                src="https://cdn.discordapp.com/attachments/825822691999219733/1016093992556961804/actionpage_ggland_actionimage.jpg">
              </v-img>
            </v-col>
            <v-col cols="8">
              <v-card-text>

                <Strong>
                  initiator: the good good land
                  <br />
                </Strong>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>

      </v-col>
      <v-col md="4" cols="12" class="my-auto">
        <p class="caption">
          <strong>request:</strong>i-002
          <br />
          <strong>request type:</strong> call for volunteers
          <br />
          <strong>request region:</strong>
          Batroun, Lebanon
                    <br />
          <strong>
            request audience:
            everyone that wants to learn & explore

          </strong>
        </p>
        <p class="body-2">
          <strong>overview:</strong>We are cocreating a 40,000m2 permaculture community land in Batroun where we harvest
          our own water, grow our own food, save our own seeds, build our own houses, and teach and learn permaculture.
        </p>
        <p class="body-2">
          <strong>campaign:</strong> Join the good good land permaculture volunteering month!

        </p>
        <p class="body-2">
          <strong>call:</strong> Do you want to learn how to create a food forest & build a community?
          <br><br> <strong>link:</strong>
          <a href="https://www.ardkon.com/thegoodgoodland" target="_Blank"> go to action</a>
          <br>
        </p>
        <p class="caption">
          <strong>September Permaculture Hands-On Trainings schedule:</strong><br>
          <br />
          WEEK 1 - September 2nd to September 8 <strong>Water Management and Harvesting</strong><br>
          WEEK 2 - September 9 to September 15 <strong>How To Grow Your Own Nutritious Food</strong><br/>
          WEEK 3 - September 16 to September 23 <strong>Sustainable Building</strong><br>
          WEEK 4 - September 23 to 29 <strong>Community Building</strong><br>
          Final Project: <strong>Draw the Design Plan for The Good Good Land</strong><br><br/>

          Just fill in this small form, share it with others that might be interested!
          See you soon on the good good land!
        </p>
      </v-col>
      <v-col md="4" cols="12" class="my-auto">
       

        <UserForm2></UserForm2>
        <p class="caption">
          <strong>terms:</strong>
          by uploading my feedback, I give the rights for ardkon to use it only in the objective of the above request
          and to the consent of the proposed campaign that I am endorsing and I am part off.
          <br />
        </p>
        <p class="text-center ma-0 pa-0">
          <v-btn v-for="icon in icons" :key="icon.icon" class="mx-4 primary--text" icon :href="icon.src"
            target="_blank">
            <v-icon size="24px">{{ icon.icon }}</v-icon>
          </v-btn>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import UserForm2 from '../components/UserForm2.vue';
export default {
  name: 'Home',
  data: () => ({
    videoId: 'EQ2qrRx5l-Y',
    initiator: false,
    icons: [{

      icon: 'mdi-facebook',
      src: 'https://www.facebook.com/ardkoncom-106260317399870'
    }, {

      icon: 'mdi-linkedin',
      src: 'https://www.linkedin.com/company/71852371/'
    }, {

      icon: 'mdi-instagram',
      src: 'https://www.instagram.com/ardkon_com/'
    }
    ],

  }),
  metaInfo() {
    return {
      title: 'The Good Good Land',
      // override the parent template and just use the above title only
      meta: [
        { name: 'description', content: 'The Good Good Land Volunteering call' },
        { property: 'og:site_name', content: "The Good Good Land" },
        { property: 'og:title', content: "The Good Good Land" },
        { property: 'title', content: "The Good Good Land" },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' }
      ]
    }

  },
  components: {
    UserForm2
},
}
</script>
