<template >
  <v-container>
    <v-row class="mt-2">
      <v-col md="4" cols="12" class="my-auto">
        <h3>
          title:
          <span class="font-weight-medium">“YOUR VOTE, MY VOTE …</span>
        </h3>
        <h5>
          objective:
          <span
            class="font-weight-medium"
          >increase the momentum of the Lebanese diaspora registration for the Lebanese elections 2022.</span>
        </h5>

        <h6>
          circle: ardkon
          <br />act: we live together, we rebel
        </h6>
        <br />
        <youtube :video-id="videoId" width="100%" ref="youtube"></youtube>
        <v-card
          style="background-color: rgba(255,255,255,0.63); cursor: pointer; "
          @click="initiator = true"
        >
          <v-row no-gutters>
            <v-col cols="4">
              <v-img
                height="81"
                src="https://ardkon.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1636833539668.png"
              ></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-text>
                <v-btn x-small right absolute bottom outlined class="text-lowercase">view more</v-btn>

                <Strong>
                  initiator:
                  <br />
                </Strong>Lea De Gobert
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
        <v-dialog v-model="initiator" centered max-width="450">
          <v-card>
            <v-card-text class="font-weight-bold pt-6">a message from our co-founder:</v-card-text>
            <v-card-text>
              Half Lebanese, half Belgian, but 100% in love with a country that I miss and that I want to help change for the better.
              If you are like me, a Lebanese at heart but not on paper, share the word. Make your own video to motivate people who can vote.
              Voting is a power that not everybody has but everybody deserves. If you have that privilege, use it for good. Most of us would love to be able to participate in the elections. Representation matters: we don’t think it will solve everything, but we are positive that it is a necessary step towards more democracy. The more voices are heard, the harder it gets to silence them.
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col md="4" cols="12" class="my-auto">
        <p class="caption">
          <strong>request:</strong>000
          <br />
          <strong>request type:</strong> call for solidarity
          <br />
          <strong>request region:</strong>
          ASIA, AUSTRALIA, EUROPE, AMERICA, AFRICA
          <br />
          <strong>
            request audience:
            NON-CITIZEN LEBANESE & THEIR MOTHERS
          </strong>
        </p>
        <p class="body-2">
          <strong>overview:</strong> a few days are left to register for the next Lebanese elections. For many of us voting is a right that we decide to exercise or not,
          but we may not be aware that there are many brothers, cousins, friends and neighbors that can’t even vote!
          <strong>Lebanese from transnational families with Lebanese mothers</strong> are not holding the citizenship and hence are
          <strong>lacking the right of democratic participation.</strong>
        </p>
        <p class="body-2">
          <strong>campaign:</strong> ardkon is calling out Lebanese citizenship holders around the world that haven’t registered yet to think of the ones that are eager to vote yet can’t. NON-CITIZEN LEBANESE are one of the lost generation of citizens that would deserve the right of identity and belonging. Hence we sense duty over responsibility as our votes don’t just represent the sides rather the choice of equality over discrimination.
        </p>
        <p class="body-2">
          <strong>call:</strong> ardkon is calling for NON-CITIZEN LEBANESE or their MOTHERS to send us a
          <strong>short video</strong> stating their names, status, location, background, story and calling: “YOUR VOTE IS MY VOTE to create a collective message to encourage the remaining Lebanese diaspora that had not yet registered to go and register.
        </p>
      </v-col>
      <v-col md="4" cols="12" class="my-auto">
        <p class="caption">
          <strong>deadline:</strong>
          please upload your video and information before the 17th of November.
          <br />
          <strong>release:</strong>
          the summary video will be released of the night of 18th of November.
          <br />
          <strong>commitment:</strong>
          ardkon aims to always be on the side of the citizen in their education and awareness of public matters. We aim always to accelerate the development of the civic life in Lebanon & beyond by focusing on the empowerment of grassroots movements and actions that have a direct positive impact on the wellbeing of the governance/citizenship relationship.
        </p>

        <user-form></user-form>
        <p class="caption">
          <strong>terms:</strong>
          by uploading my video, I give the rights for ardkon to use it only in the objective of the above request and to the consent of the proposed campaign that I am endorsing. I am agreeing to be tagged with the social media accounts.
          <br />
        </p>
        <p class="text-center ma-0 pa-0">
          <v-btn
            v-for="icon in icons"
            :key="icon.icon"
            class="mx-4 primary--text"
            icon
            :href="icon.src"
            target="_blank"
          >
            <v-icon size="24px">{{ icon.icon }}</v-icon>
          </v-btn>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import UserForm from '@/components/UserForm'
export default {
  name: 'Home',
  data: () => ({
    videoId: 'EQ2qrRx5l-Y',
    initiator: false,
    icons: [{

      icon: 'mdi-facebook',
      src: 'https://www.facebook.com/ardkoncom-106260317399870'
    }, {

      icon: 'mdi-linkedin',
      src: 'https://www.linkedin.com/company/71852371/'
    }, {

      icon: 'mdi-instagram',
      src: 'https://www.instagram.com/ardkon_com/'
    }
    ],

  }),
  metaInfo() {
    return {
      title: 'your vote, my vote… | r#000 ',
      // override the parent template and just use the above title only
      meta: [
        { name: 'description', content: 'increase the momentum of the Lebanese diaspora registration for the Lebanese elections 2022' },
        { property: 'og:site_name', content: "you vote, my vote..." },
        { property: 'og:title', content: "you vote, my vote..." },
        { property: 'title', content: "you vote, my vote..." },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' }
      ]
    }

  },
  components: {
    UserForm,

  },
}
</script>
