<template >
  <v-container>
    <v-row class="mt-2">
      <v-col md="4" cols="12" class="my-auto">
        <h3>
          title:
          <span class="font-weight-medium"> MeliBee journey autumn-winter course sign-up</span>
        </h3>
        <h5>
          objective:
          <span class="font-weight-medium">share knowledge about plant medicine & safe usage of medicinal plants </span>
        </h5>

        <h6>
          circle:
          <br />act: we share what we know
        </h6>
        <br />
        <div style="position: relative; padding-bottom: 57.05229793977813%; height: 0;"><iframe
            src="https://www.youtube.com/embed/9juWXIJlHfo" frameborder="0" webkitallowfullscreen mozallowfullscreen
            allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div> <br>
        <v-card style="background-color: rgba(255,255,255,0.63); cursor: pointer; " @click="initiator = true">
          <v-row no-gutters>
            <v-col cols="4">
              <v-img height="81"
                src="https://cdn.discordapp.com/attachments/1030430670020939847/1030449332316553246/NEWS_2.jpg">
              </v-img>
            </v-col>
            <v-col cols="8">
              <v-card-text>

                <Strong>
                  initiator: Melibee
                  <br />
                </Strong>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>

      </v-col>
      <v-col md="4" cols="12" class="my-auto">
        <p class="caption">
          <strong>request:</strong>MeliBee journey autumn-winter course
          <br />
          <strong>request type:</strong> course sign up
          <br />
          
          <strong>request region:</strong>
          Mount Lebanon & online
          <br />
          <strong>
            request audience:
            individuals that are interested to learn about herbal plants with their properties & how to prepare remedies 
          </strong>
        </p>
        <p class="caption text-sm">
          <strong>overview:</strong><span class="caption">course with 2 on-site workshop, on-/offline course meetings & support, including online videos & material (9 sections)</span>
          <br>
          <strong>course price:</strong><span class="caption">100 $ or 25$/section</span> 
          <br>
          <br>
          over 4 months (October 2022 to January 2023)
          First workshop date is the 22nd October 2023, others will follow throughout the course.
          <br>
       
          Dive into the Wild Kingdom and discover how to use Plants as Medicine safely, & effectively, so you can take
          your health and family's back into your own hands!
          <br><br>
          I'm Melissa (Mel, Meli or Bee) I'm an artist, an ayurvedic herbalist, a gardener, a wild forager & chef. I am
          super passionate about teaching and sharing with you my knowledge to help you awaken the healer within you.
          <br><br>

          The World Of Herbalism can enhance your life! Allow me to be a guide on your journey down the Herbalist's Path
          and heal the world together with Magical Herbal Potions.
        </p>
  
        <p class="body-2">
           <strong>Find out more:</strong><br>
           <a href="">MeliBee‘s actionpage </a> 

          <a href="https://www.ardkon.com/MeliBee-journey" target="_Blank"> go to action</a>
          <br>
           <strong></strong>
           <a href=""></a> 

          <a href="https://bafybeifvr3e3fd6xztzyvr5rpatf3zu4rzk2iwymncg6hqdu74awlywhke.ipfs.nftstorage.link/" target="_Blank"> course outline pdf  </a>
          <br>
        </p>
        <p class="caption">
          <strong>
</strong><br>
         
        </p>
      </v-col>
      <v-col md="4" cols="12" class="my-auto">


        <UserForm3></UserForm3>
        <p class="" style="font-size: 10px;">
          <strong>terms:</strong>
            <br />
            By signing up to the course you are booking the full course experience with the life sessions, videos & material on the platform, as well as on- & offline support from the course mentor Melissa.

          I hereby confirm my sign-up for the above stated fee & payment conditions.<br>Included in the platform fee are 5% ardkon platform fee. You will receive payment instructions with your confirmation email. Fees for local or international payments methods may apply.  </p>
        <p class="text-center ma-0 pa-0">
          <v-btn v-for="icon in icons" :key="icon.icon" class="mx-4 primary--text" icon :href="icon.src"
            target="_blank">
            <v-icon size="24px">{{ icon.icon }}</v-icon>
          </v-btn>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import UserForm3 from '../components/UserForm3.vue';
export default {
  name: 'Home',
  data: () => ({
    videoId: 'EQ2qrRx5l-Y',
    initiator: false,
    icons: [{

      icon: 'mdi-facebook',
      src: 'https://www.facebook.com/ardkoncom-106260317399870'
    }, {

      icon: 'mdi-linkedin',
      src: 'https://www.linkedin.com/company/71852371/'
    }, {

      icon: 'mdi-instagram',
      src: 'https://www.instagram.com/ardkon_com/'
    }
    ],

  }),
  metaInfo() {
    return {
      title: ' MeliBee journey',
      // override the parent template and just use the above title only
      meta: [
        { name: 'description', content: ' MeliBee journey' },
        { property: 'og:site_name', content: " MeliBee journey" },
        { property: 'og:title', content: " MeliBee journey" },
        { property: 'title', content: " MeliBee journey" },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' }
      ]
    }

  },
  components: {
    
    UserForm3
},
}
</script>
