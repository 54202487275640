<template>
    <div class="my-0 py-0">
        <h5 class="mb-0">{{ input_info.title }}</h5>
        <div v-if="input_info.field_type === 'text-field'">
            <v-text-field
                dense
                :type="input_info.input_type"
                outlined
                :label="input_info.label"
                v-model="input_info.value"
            ></v-text-field>
        </div>
        <div v-if="input_info.field_type === 'text-area'">
            <v-textarea outlined dense :label="input_info.label" v-model="input_info.value"></v-textarea>
        </div>
        <div v-if="input_info.field_type === 'select'">
            <v-select
                outlined
                class="text-lowercase"
                :items="input_info.items"
                :label="input_info.label"
                dense
                v-model="input_info.value"
            ></v-select>
        </div>
        <div v-if="input_info.field_type === 'checkbox'">
            <span v-for="input_check in input_info.items" :key="input_check" class="py-0 my-0 ">
                <v-checkbox class="caption"
                dense
                    v-model="input_info.value"
                    :label="input_check"
                    :value="input_check"
                ></v-checkbox>
            </span>
           
        </div>
        <div v-if="input_info.field_type === 'select-input'">
            <v-select
                outlined
                :items="input_info.items"
                :label="input_info.label"
                dense
                v-model="input_info.value"
            ></v-select>
            <v-text-field
                v-if="input_info.value === input_info.input_value_condition"
                :type="input_info.input_type"
                dense
                outlined
                :label="input_info.label_2"
                v-model="input_info.value_2"
            ></v-text-field>
        </div>

       
    </div>
</template>
<script>

export default {
    name: 'FormInput',
    components: {
        
    },
    props: {
        input_info: Object
    }
}
</script>