<template>
    <v-row>
        <v-col cols="6">
            <v-text-field dense outlined v-model="form.first_name" label="name"></v-text-field>
            <v-text-field dense outlined v-model="form.email" label="email"></v-text-field>


            <v-text-field dense outlined v-model="form.phone" label="phone"></v-text-field>
        </v-col>
        <v-col cols="6">
            <v-text-field dense outlined v-model="form.linked_in_profile" label="linkedin profile"></v-text-field>
            <v-text-field dense outlined v-model="form.instagram_account" label="instagram account"></v-text-field>
            <v-text-field dense outlined v-model="form.twitter_account" label="twitter"></v-text-field>
           
        </v-col>
        <v-col cols="12" class="" v-for="form in info" :key="form.name">
                <FormInput :input_info="form"></FormInput>

            </v-col>
        <v-col cols="12">
            <v-btn dense block color="primary" @click="submitForm" class="text-lowercase">register</v-btn>

            <p :class="submitted ? 'primary--text' : 'red--text'" class="caption text-center">{{ message }}</p>
        </v-col>
    </v-row>
</template>
<script>

import { Storage } from 'aws-amplify';
import FormInput from './FormInput.vue';

export default {
    name: 'user-form-1',
    components: {
        FormInput
    },
    data: () => ({
        map: false,
        mapStyle: "mapbox://styles/planet369/ckrdwj57j2w0m18mjfzizwmm3",
        mapboxAccessToken: 'pk.eyJ1IjoicGxhbmV0MzY5IiwiYSI6ImNrcmR3ZXZ6ZDBpbHgyb29laHR2d3pjcHUifQ.riIQ-PY811vasvyycMyn3g',
        coordinates: [-111.549668, 39.014],
        file: {},
        form: {
            first_name: '',
            email: '',
            phone: '',
            location: '',
            linked_in_profile: '',
            instagram_account: '',
            twitter_account: '',
            video_url: '',
            request_id: '0f8603d5-c5f8-4f6d-b0fe-2f80357b6a48'
        },
        message: '',
        submitted: false,
        submit: false,
        info: [{
              title: 'Under which category would you attend the round table?',
              field_type: 'select',
              label: 'Under which category would you attend the round table?',
              input_type: 'select',
      
              items: ['candidate or party member', 'journalist, media', 'citizen', ' analyst', 'researcher'],
              value: '',
              input_id: 'action_link'
      
            }, 
     {
              title: '  ',
              field_type: 'checkbox',
              label: '',
              input_type: 'checkbox',
      
              items: ['I hereby accept that the organizers of the event may take photos, record & broadcast audiovisual footage of my contribution in the roundtable event for the further development & outreach of the platform.', "I don't want footage or recordings of me to be taken or published."],
              value: '',
              input_id: 'action_link'
      
            }, 
        ]
    }),
    methods: {
        createGenerativeNumber() {
            return Math.floor(100000000 + Math.random() * 900000000)
        },
        mapOpen() {
            this.map = true
            console.log(this.$refs.map)
            this.$refs.map.loadMap();
        },
        async put() {
            const result = await Storage.put("test.txt", "Hello");
            console.log(result)

        },
        async add() {
            this.submit = true

            const result = await Storage.put(this.createGenerativeNumber(), this.file);
            console.log(result)
            this.form.video_url = 'https://requests6b75835a6b6b43ceae12efe85948b077.s3.eu-central-1.amazonaws.com/public/' + result.key

        },
        async getFiles() {
            Storage.list('/public/') // for listing ALL files without prefix, pass '' instead
                .then(result => console.log(result))
                .catch(err => console.log(err));
        },
        async submitForm() {
            if ( this.form.first_name !== '' && this.form.email !== '' && this.info[0].value !== '' ) {
                this.message = 'Thank you for submitting your info'

                var info1 = {
                   info:  this.info ,
                   form: this.form
                }
                await Storage.put(this.createGenerativeNumber() + "-request-axb.json", JSON.stringify(info1));

                window.location.reload()
                this.form = {}
                this.submitted = true
            } else {
                this.message = 'All fields are required'
                this.submitted = false

            }
        }
    }
}
</script>