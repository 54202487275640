import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import r_001 from '../views/r-001.vue'
import r_002 from '../views/r-002.vue'
import r_003 from '../views/r-003.vue'
import r_004 from '../views/r-004.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/r-000',
    name: 'Home',
    component: Home
  },{
    path: '/r-001',
    name: 'r-001',
    component: r_001
  
  },{
    path: '/r-002',
    name: 'r-002',
    component: r_002
  
  },{
    path: '/r-003',
    name: 'r-003',
    component: r_003
  
  },{
    path: '/r-004',
    name: 'r-004',
    component: r_004
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
