<template>
    <v-row>
        <v-col cols="6">
            <v-text-field dense outlined v-model="form.first_name" label="name"></v-text-field>
            <v-text-field dense outlined v-model="form.last_name" label="email"></v-text-field>

            
            <v-text-field dense outlined v-model="form.location" label="location"></v-text-field>
        </v-col>
        <v-col cols="6">
            <v-text-field dense outlined v-model="form.linked_in_profile" label="linkedin profile"></v-text-field>
            <v-text-field dense outlined v-model="form.instagram_account" label="instagram account"></v-text-field>
            <v-file-input
                dense
                outlined
                v-model="file"
                @change="add"
                label="upload file"
            ></v-file-input>
            <v-progress-linear
                v-if="form.video_url === '' && submit === true"
                color="primary accent-4"
                indeterminate
                rounded
                height="6"
            ></v-progress-linear>
        </v-col>
        <v-col cols="12">
            <v-btn dense block color="primary" @click="submitForm" class="text-lowercase">submit feedback</v-btn>

            <p
                :class="submitted ? 'primary--text' : 'red--text'"
                class="caption text-center"
            >{{ message }}</p>
        </v-col>
    </v-row>
</template>
<script>

import { Storage } from 'aws-amplify';
import axios from 'axios';
export default {
    name: 'user-form-1',
    components: {
        
    },
    data: () => ({
        map: false,
        mapStyle: "mapbox://styles/planet369/ckrdwj57j2w0m18mjfzizwmm3",
        mapboxAccessToken: 'pk.eyJ1IjoicGxhbmV0MzY5IiwiYSI6ImNrcmR3ZXZ6ZDBpbHgyb29laHR2d3pjcHUifQ.riIQ-PY811vasvyycMyn3g',
        coordinates: [-111.549668, 39.014],
        file: {},
        form: {
            first_name: '',
            last_name: '',
            location: '',
            linked_in_profile: '',
            instagram_account: '',
            video_url: '',
            request_id: '0f8603d5-c5f8-4f6d-b0fe-2f80357b6a48'
        },
        message: '',
        submitted: false,
        submit: false
    }),
    methods: {
        createGenerativeNumber(){
            return Math.floor(100000000 + Math.random() * 900000000)
        },
        mapOpen() {
            this.map = true
            console.log(this.$refs.map)
            this.$refs.map.loadMap();
        },
        async put() {
            const result = await Storage.put("test.txt", "Hello");
            console.log(result)

        },
        async add() {
            this.submit = true

            const result = await Storage.put(this.createGenerativeNumber(), this.file);
            console.log(result)
            this.form.video_url = 'https://camerav101c7a83ec0fc24f08b37eb81a967c8276192744-cam.s3.eu-central-1.amazonaws.com/public/'+ result.key

        },
        async getFiles() {
            Storage.list('/public/') // for listing ALL files without prefix, pass '' instead
                .then(result => console.log(result))
                .catch(err => console.log(err));
        },
        submitForm() {
            if (this.form.video_url !== '' && this.form.first_name !== '' && this.form.last_name !== '' && this.form.location !== '' && this.form.linked_in_profile !== '' && this.form.instagram_account !== '') {
                this.message = 'Thank you for submitting your info'


                axios

                    .post("https://apiss.ardkon.com/requestDemo?basics=platform", this.form)
                    .then((response) => {

                        console.log(response.data);

                    })
                    .catch((error) => {
                        console.log(error);
                    });
                this.form = {}
                this.submitted = true
            } else {
                this.message = 'All fields are required'
                this.submitted = false

            }
        }
    }
}
</script>