<template >
  <v-container>
    <v-row class="mt-2">
      <v-col md="4" cols="12" class="my-auto">
        <h3>
          title:
          <span class="font-weight-medium"> axb roundtable meeting: digitalization
            in the municipality elections </span>
        </h3>
        <h5>
          objective:
          <span class="font-weight-medium lowercase">decentralization for transparency & CITIZEN INVOLVEMENT </span>
        </h5>

        <h6>
          circle: axb
          <br />act: we live together, we rebel
        </h6>
        <br />
        <div style="position: relative; padding-bottom: 57.05229793977813%; height: 0;"><iframe
            src="https://www.youtube.com/embed/fE8CTqdiEXM" frameborder="0" webkitallowfullscreen mozallowfullscreen
            allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div><br>

        
        <v-card style="background-color: rgba(255,255,255,0.63); cursor: pointer; " @click="initiator = true">
          <v-row no-gutters>
            <v-col cols="4">
              <v-img height="81"
                src="https://media.discordapp.net/attachments/835173834855284787/835478398260346890/logoled-1.png?width=648&height=463">
              </v-img>
            </v-col>
            <v-col cols="8">
              <v-card-text>

                <Strong>
                  initiator: axb
                  <br />
                </Strong>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
        <v-dialog v-model="initiator" centered max-width="450">
          <v-card>
            <v-card-text class="my-4">

              axb is a digital debate & fundraising platform for candidacy campaigning & fundraising. ardkon.com, a
              democratized & decentralized digital ecosystem that accelerates the participation of grassroots in the
              civic life, is approaching partners to unite for the next municipalities elections in 2023 to develop &
              promote a transparent & empowering campaigns by the civil society that is still it first growth stages in
              the political life in Lebanon. </v-card-text>
          </v-card>
        </v-dialog>
        <p style="font-size: 9px;" class="my-2">
          <strong>
            Disclaimers:
          </strong><br>
          This event & its organizers are not affiliated to any political party or organization nor aim to represent their agendas. ardkon, the venue & organizers of this meeting are not responsible for the contribution of the participants during the whole events, may it be live, in broadcast or shared in recorded material & don't align with or endorse all opinions brought forward. The organizers are refusing to tolerate any expression of discriminatory nature or the promotion of extremist ideologies. They, in coordination with the venue may exercise the right to expel individuals from the meeting & premises.

        </p>
      </v-col>
      <v-col md="4" cols="12" class="my-auto">
        <p class="caption">
          <strong>request:</strong>004
          <br />
          <strong>request type:</strong> call for participation
          <br />

          <strong>request region:</strong>
          Lebanon (all regions)
          <br />
          <strong>
            request audience:
            citizens, candidate or party member; journalist, media
          </strong>
        </p>
        <p class="caption text-sm">
          OCTOBER 29, 2022<br>
          03:00-7:00 PM<br>
          Grand Hotel Naas, Naas-Bickfaya<br>
          <strong>brief:</strong><span class="caption">ardkon is inviting stakeholders to share their views on the
            future of digitalization in Lebanese politics. </span>
          <br>
          <span class="caption">axb is an online platform that will centralize information on political events and
            elections & aims to be the first step of many in the transformation of the civil society, by enhancing the
            relationship between the citizenship & governance.</span>
          <br>
          <br>
          This event is free of charge, donations for the preparation & ongoing development of the platform will be
          appreciated.
          <br>
          <br>

          We are inviting (past, present and future) candidates, political parties, analysts, researchers, journalists
          and non-political initiatives to debate & co-develop solutions all together during this step on the way to the
          launch.
          <br><br>

        </p>

        <p class="body-2">
          <strong>More information: </strong><br>
          <a href="https://goo.gl/maps/ZQWbJkGtoFduFZaV7" target="_Blank">Location </a><br>
          <a href="https://bafybeicvvndy3ap2pwnets7rdnsvz5v5xknchy4szer3bu7czpg4rfq6ni.ipfs.nftstorage.link/" target="_Blank">pdf </a>

          <br>
          <strong></strong>
          <a href=""></a>


        </p>

      </v-col>
      <v-col md="4" cols="12" class="my-auto">


        <UserForm4></UserForm4>
       
        <p class="text-center ma-0 pa-0">
          <v-btn v-for="icon in icons" :key="icon.icon" class="mx-4 primary--text" icon :href="icon.src"
            target="_blank">
            <v-icon size="24px">{{ icon.icon }}</v-icon>
          </v-btn>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import UserForm4 from '../components/UserForm4.vue';
export default {
  name: 'Home',
  data: () => ({
    videoId: 'EQ2qrRx5l-Y',
    initiator: false,
    icons: [{

      icon: 'mdi-facebook',
      src: 'https://www.facebook.com/ardkoncom-106260317399870'
    }, {

      icon: 'mdi-linkedin',
      src: 'https://www.linkedin.com/company/71852371/'
    }, {

      icon: 'mdi-instagram',
      src: 'https://www.instagram.com/ardkon_com/'
    }
    ],

  }),
  metaInfo() {
    return {
      title: 'axb - participation',
      // override the parent template and just use the above title only
      meta: [
        { name: 'description', content: 'axb' },
        { property: 'og:site_name', content: "axb" },
        { property: 'og:title', content: "axb" },
        { property: 'title', content: "axb" },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' }
      ]
    }

  },
  components: {

    UserForm4
  },
}
</script>
