import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import vuetify from './plugins/vuetify'
Amplify.configure(awsconfig);

import VueYoutube from 'vue-youtube'
Vue.use(VueYoutube)
Vue.config.productionTip = false
import VueMeta from 'vue-meta'

import VueAnalytics from 'vue-analytics';

// router setup

Vue.use(VueAnalytics, {
    id: 'UA-176108492-6',
    router
})

Vue.use(VueMeta)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
