<template>
  <v-app class="appp">
    

    <v-main class="main">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
    
  },
  data: () => ({
    file: {}
    //
  }),
 
};
</script>
<style>
 ::-webkit-scrollbar {
    width: 3px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: darkgrey;
  }
  
  ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  }
.appp{
  background-image: url("./assets/Map1.svg") !important;
    background-size: 100% 100% !important;
    background-color: #fcfcfc !important;
    height: 100vh;
    overflow: hidden;
}

.main{
  
    height: 98vh;
    overflow-y: scroll;
}
</style>
