<template >
  <v-container>
    <v-row class="mt-2">
      <v-col md="4" cols="12" class="my-auto">
        <h3>
          title:
          <span class="font-weight-medium"> axb feedback 001</span>
        </h3>
        <h5>
          objective:
          <span
            class="font-weight-medium"
          >create a full detailed overview of the first mvp1 of axb</span>
        </h5>

        <h6>
          circle: ardkon
          <br />act: we work for all, we share what we know
        </h6>
        <br />
        <div style="position: relative; padding-bottom: 57.05229793977813%; height: 0;"><iframe src="https://www.loom.com/embed/39fc58dd20954176bcf1ed3f9a6e6de0" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>  <br>      <v-card
          style="background-color: rgba(255,255,255,0.63); cursor: pointer; "
          @click="initiator = true"
        >
          <v-row no-gutters>
            <v-col cols="4">
              <v-img
                height="81"
                src="https://ardkon.s3.eu-central-1.amazonaws.com/profile-members/hero-to-back-1637767394659.png"
              ></v-img>
            </v-col>
            <v-col cols="8">
              <v-card-text>

                <Strong>
                  initiator: Simon Semaan<br> CTO of ardkon.com
                  <br />
                </Strong>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
       
      </v-col>
      <v-col md="4" cols="12" class="my-auto">
        <p class="caption">
          <strong>request:</strong>i-001
          <br />
          <strong>request type:</strong> call for feedback & review
          <br />
          <strong>request region:</strong>
          ardkon core circle
          <br />
          <strong>
            request audience:
            co-founders

          </strong>
        </p>
        <p class="body-2">
          <strong>overview:</strong>ardkon.com believe that the digitalization of public debates and campaigns will allow the increase of grassroots candidates in the participation in the Lebanese elections 2022.
        </p>
        <p class="body-2">
          <strong>campaign:</strong> ardkon is launching axb campaign to ensure that with the right means of engagement and trust the diaspora and the international community will leverage a digital portal to build well informed decision making process for selecting thier next candidates in 2022.

        </p>
        <p class="body-2">
          <strong>call:</strong> ardkon development team is call the cofounders for a feedback session to review the early development of mvp1 and take care of the development of the language and communication aspect of the interface experience as the development team addresses the financial engineering of the mvp.
          <br><br> <strong>link:</strong>
          <a href="https://axb.d2bhds5afeoout.amplifyapp.com" target="_Blank"> go to mvp</a>
        <br> <strong>feedback link:</strong>
          <a href="https://www.loom.com/share/39fc58dd20954176bcf1ed3f9a6e6de0" target="_Blank"> go to link</a>
        
        </p>
      </v-col>
      <v-col md="4" cols="12" class="my-auto">
        <p class="caption">
          <strong>deadline:</strong>
          please upload your feedback before the 3rd of December. 
          <br />
          <strong>release:</strong>
          the summary of all your feedbacks will be released on the 5th of December with direction updates based on your input. 
          <br />
          <strong>commitment:</strong>
          to ensure that the cofounders and the development team are aligned and investing in the right message with thier audience and users. The development team is expecting content fixes, development and material to enhance the full logic of the development by ardkon community.
       </p>

        <user-form-1></user-form-1>
        <p class="caption">
          <strong>terms:</strong>
          by uploading my feedback, I give the rights for ardkon to use it only in the objective of the above request and to the consent of the proposed campaign that I am endorsing and I am part off.
<br />
        </p>
        <p class="text-center ma-0 pa-0">
          <v-btn
            v-for="icon in icons"
            :key="icon.icon"
            class="mx-4 primary--text"
            icon
            :href="icon.src"
            target="_blank"
          >
            <v-icon size="24px">{{ icon.icon }}</v-icon>
          </v-btn>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import UserForm1 from '@/components/UserForm1'
export default {
  name: 'Home',
  data: () => ({
    videoId: 'EQ2qrRx5l-Y',
    initiator: false,
    icons: [{

      icon: 'mdi-facebook',
      src: 'https://www.facebook.com/ardkoncom-106260317399870'
    }, {

      icon: 'mdi-linkedin',
      src: 'https://www.linkedin.com/company/71852371/'
    }, {

      icon: 'mdi-instagram',
      src: 'https://www.instagram.com/ardkon_com/'
    }
    ],

  }),
  metaInfo() {
    return {
      title: 'axb feedback 001 ',
      // override the parent template and just use the above title only
      meta: [
        { name: 'description', content: 'create a full detailed overview of the first mvp1 of axb' },
        { property: 'og:site_name', content: "axb feedback 001" },
        { property: 'og:title', content: "axb feedback 001" },
        { property: 'title', content: "axb feedback 001." },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' }
      ]
    }

  },
  components: {
    UserForm1,

  },
}
</script>
